import { KeyOf } from '@zaritalk/types';

const FONT_WEIGHT = {
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  BOLD: 700,
} as const;

const LINE_HEIGHT = {
  EXTRA_NARROW: '100%',
  NARROW: '125%',
  MIDDLE: '136%',
  WIDE: '164%',
  MIDDLE_WIDE: '174%',
  EXTRA_WIDE: '184%',
} as const;

export const TYPOGRAPHY_VARIANTS = {
  '28_BOLD_NARROW': {
    fontSize: '28px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '22_BOLD_NARROW': {
    fontSize: '22px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '20_BOLD_MIDDLE': {
    fontSize: '20px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '18_BOLD_EXTRA_NARROW': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '18_BOLD_NARROW': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '18_BOLD_MIDDLE': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '18_BOLD_WIDE': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '18_BOLD_EXTRA_WIDE': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '16_BOLD_EXTRA_NARROW': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '16_BOLD_NARROW': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '16_BOLD_MIDDLE': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '16_BOLD_WIDE': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '16_BOLD_EXTRA_WIDE': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '14_BOLD_EXTRA_NARROW': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '14_BOLD_NARROW': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '14_BOLD_MIDDLE': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '14_BOLD_WIDE': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '14_BOLD_EXTRA_WIDE': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '18_MEDIUM_EXTRA_NARROW': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '18_MEDIUM_NARROW': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '18_MEDIUM_MIDDLE': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '18_MEDIUM_WIDE': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '18_MEDIUM_EXTRA_WIDE': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '16_MEDIUM_EXTRA_NARROW': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '16_MEDIUM_NARROW': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '16_MEDIUM_MIDDLE': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '16_MEDIUM_WIDE': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '16_MEDIUM_EXTRA_WIDE': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '14_MEDIUM_EXTRA_NARROW': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '14_MEDIUM_NARROW': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '14_MEDIUM_MIDDLE': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '14_MEDIUM_WIDE': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '14_MEDIUM_EXTRA_WIDE': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '18_REGULAR_EXTRA_NARROW': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '18_REGULAR_NARROW': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '18_REGULAR_MIDDLE': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '18_REGULAR_WIDE': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '18_REGULAR_EXTRA_WIDE': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '16_REGULAR_EXTRA_NARROW': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '16_REGULAR_NARROW': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '16_REGULAR_MIDDLE': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '16_REGULAR_WIDE': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '16_REGULAR_MIDDLE_WIDE': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.MIDDLE_WIDE,
  },
  '16_REGULAR_EXTRA_WIDE': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '14_REGULAR_EXTRA_NARROW': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '14_REGULAR_NARROW': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '14_REGULAR_MIDDLE': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '14_REGULAR_WIDE': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '14_REGULAR_EXTRA_WIDE': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '18_LIGHT_EXTRA_NARROW': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '18_LIGHT_NARROW': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '18_LIGHT_MIDDLE': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '18_LIGHT_WIDE': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '18_LIGHT_EXTRA_WIDE': {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '16_LIGHT_EXTRA_NARROW': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '16_LIGHT_NARROW': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '16_LIGHT_MIDDLE': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '16_LIGHT_WIDE': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '16_LIGHT_EXTRA_WIDE': {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '14_LIGHT_EXTRA_NARROW': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '14_LIGHT_NARROW': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '14_LIGHT_MIDDLE': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '14_LIGHT_WIDE': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '14_LIGHT_EXTRA_WIDE': {
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.LIGHT,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '13_REGULAR_EXTRA_NARROW': {
    fontSize: '13px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '13_BOLD_EXTRA_NARROW': {
    fontSize: '13px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '13_REGULAR_EXTRA_WIDE': {
    fontSize: '13px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '13_REGULAR_MIDDLE': {
    fontSize: '13px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '12_BOLD_EXTRA_NARROW': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '12_BOLD_NARROW': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '12_BOLD_MIDDLE': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '12_BOLD_WIDE': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '12_BOLD_EXTRA_WIDE': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '12_MEDIUM_EXTRA_NARROW': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '12_MEDIUM_NARROW': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '12_MEDIUM_MIDDLE': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '12_MEDIUM_WIDE': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '12_MEDIUM_EXTRA_WIDE': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '12_REGULAR_EXTRA_NARROW': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '12_REGULAR_NARROW': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '12_REGULAR_MIDDLE': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.MIDDLE,
  },
  '12_REGULAR_WIDE': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.WIDE,
  },
  '12_REGULAR_EXTRA_WIDE': {
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.EXTRA_WIDE,
  },
  '11_BOLD_NARROW': {
    fontSize: '11px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '11_MEDIUM_NARROW': {
    fontSize: '11px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '11_REGULAR_EXTRA_NARROW': {
    fontSize: '11px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '10_BOLD_EXTRA_NARROW': {
    fontSize: '10px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '10_MEDIUM_EXTRA_NARROW': {
    fontSize: '10px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
  '10_MEDIUM_NARROW': {
    fontSize: '10px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: LINE_HEIGHT.NARROW,
  },
  '10_REGULAR_EXTRA_NARROW': {
    fontSize: '10px',
    fontWeight: FONT_WEIGHT.REGULAR,
    lineHeight: LINE_HEIGHT.EXTRA_NARROW,
  },
};

export type TTypographyVariant = KeyOf<typeof TYPOGRAPHY_VARIANTS>;
