export const ZARITALK_ROOM_DETAIL_CLOSE_TOOLTIP = 'ZARITALK_ROOM_DETAIL_CLOSE_TOOLTIP';
export const ZARITALK_ROOM_DETAIL_PAYMENT_TOOLTIP = 'ZARITALK_ROOM_DETAIL_PAYMENT_TOOLTIP';

export const ZARITALK_LOGIN_UTM = 'ZARITALK_LOGIN_UTM';
export const KAKAO_REDIRECT_NEXT = 'KAKAO_REDIRECT_NEXT';
export const ZARITALK_KAKAO_LOGIN_REFERER = 'ZARITALK_KAKAO_LOGIN_REFERER';
export const ZARITALK_SELECTED_MEMBER_TYPE = 'ZARITALK_SELECTED_MEMBER_TYPE';
export const ZARITALK_CONTACT_AUTHENTICATION = 'ZARITALK_CONTACT_AUTHENTICATION';
export const ZARITALK_CONTACT_MONTHLY_PAYMENT_DATE_FUNNEL_PASSED =
  'ZARITALK_CONTACT_MONTHLY_PAYMENT_DATE_FUNNEL_PASSED';

export const ZARITALK_PAYMENT_TOOLTIP = 'ZARITALK_PAYMENT_TOOLTIP';
export const ZARITALK_HOST_INFO_TOOLTIP = 'ZARITALK_HOST_INFO_TOOLTIP';
export const ZARITALK_HOST_BILL_CHECK_TOOLTIP = 'ZARITALK_HOST_BILL_CHECK_TOOLTIP';

export const ZARITALK_COMMUNITY_NAV_TOOLTIP = 'ZARITALK_COMMUNITY_NAV_TOOLTIP';
export const ZARITALK_COMMUNITY_INDUCE_SHARE_TOOLTIP = 'ZARITALK_COMMUNITY_INDUCE_SHARE_TOOLTIP';

export const ZARITALK_SHOW_BILL = 'ZARITALK_SHOW_BILL';
export const ZARITALK_IS_MEMO_FIRST = 'ZARITALK_IS_MEMO_FIRST';
export const ZARITALK_IS_FIRST_BLOCKED_NOTI = 'ZARITALK_IS_FIRST_BLOCKED_NOTI';
export const ZARITALK_CONTACTS_PERMISSION_POPUP = 'ZARITALK_CONTACTS_PERMISSION_POPUP';

// Community
export const COMMUNITY_LATEST_SEARCH_QUERY = 'COMMUNITY_LATEST_SEARCH_QUERY';

// Realtor
export const REALTOR_DETAIL_DATA = 'GONGSIL_DETAIL_DATA';

export const REALTOR_PREEMPTION_TOOLTIP = 'GONGSIL_PREEMPTION_TOOLTIP';
export const REALTOR_NON_PREEMPTION_TOOLTIP = 'GONGSIL_NON_PREEMPTION_TOOLTIP';
export const BROKERAGE_FEE_AGREEMENT = 'BROKERAGE_FEE_AGREEMENT';

// App Network Info(string)
// example : {"details":{"isConnectionExpensive":true,"carrier":"Android","cellularGeneration":"4g"},"isInternetReachable":true,"type":"cellular","isConnected":true,"isWifiEnabled":false}
export const CLIENT_NETWORK_INFO = 'CLIENT_NETWORK_INFO';

// PAY
export const ZARITALK_LESSEE_PAYMENT_PAID_POINT = 'ZARITALK_LESSEE_PAYMENT_PAID_POINT';
export const ZARITALK_LESSEE_PAYMENT_REFERRER_POPUP_CLOSE_DATE = 'ZARITALK_LESSEE_PAYMENT_REFERRER_POPUP_CLOSE_DATE';

// Real Estate Map
export const REAL_ESTATE_MAP_ZOOM_LEVEL = 'REAL_ESTATE_MAP_ZOOM_LEVEL';
export const REAL_ESTATE_MAP_CENTER_POSITION = 'REAL_ESTATE_MAP_CENTER_POSITION';
export const REAL_ESTATE_MAP_POSITION_BOUNDS = 'REAL_ESTATE_MAP_POSITION_BOUNDS';
export const REAL_ESTATE_MAP_FILTER_VALUES = 'REAL_ESTATE_MAP_FILTER_VALUES_V2';
export const REAL_ESTATE_FAVORITE_REGION_TOOLTIP = 'REAL_ESTATE_FAVORITE_REGION_TOOLTIP';
export const REAL_ESTATE_MAP_SEARCH_RESULTS = 'REAL_ESTATE_MAP_SEARCH_RESULTS';

// Vacancy
export const VACANCY_FILTER_VALUES = 'VACANCY_FILTER_VALUES';
export const VACANCY_MAP_ZOOM_LEVEL = 'VACANCY_MAP_ZOOM_LEVEL';
export const VACANCY_MAP_CENTER_POSITION = 'VACANCY_MAP_CENTER_POSITION';
export const VACANCY_MAP_POSITION_BOUNDS = 'VACANCY_MAP_POSITION_BOUNDS';
export const VACANCY_MAP_SEARCH_RESULTS = 'VACANCY_MAP_SEARCH_RESULTS';

// TODO: localStorage와 sessionStorage, cookie를 각 객체로 구분하여 정리

export const LOCAL_STORAGE_KEY = {
  IS_REVIEW_INDUCE_POPUP_CTA_BUTTON_CLICKED: 'IS_REVIEW_INDUCE_POPUP_CTA_BUTTON_CLICKED',
  REGISTERED_REAL_RESIDENCE_REVIEW_IMAGES: 'REGISTERED_REAL_RESIDENCE_REVIEW_IMAGES',
  LAST_CLICKED_DATE_FROM_COMMUNITY_AUTHENTICATION_POPUP: 'LAST_CLICKED_DATE_FROM_COMMUNITY_AUTHENTICATION_POPUP',
  COMMUNITY_EXPERIENCE_APP_REVIEW_POPUP: 'COMMUNITY_EXPERIENCE_APP_REVIEW_POPUP',
  APP_REVIEW_POPUP_AFTER_LESSOR_PAYMENT: 'APP_REVIEW_POPUP_AFTER_LESSOR_PAYMENT',
  TYPEFORM_APP_REVIEW_POPUP: 'TYPEFORM_APP_REVIEW_POPUP',
  PAYMENT_REVIEW_BANNER: 'PAYMENT_REVIEW_BANNER',
  PHONE_NUMBER_CHANGE_ACCEPT: 'PHONE_NUMBER_CHANGE_ACCEPT',
  PHONE_NUMBER_CHANGE_DENY: 'PHONE_NUMBER_CHANGE_DENY',
} as const;

export const SESSION_STORAGE_KEY = {
  REVIEW_HOME_FEED_SCROLL_HEIGHT: 'REVIEW_HOME_FEED_SCROLL_HEIGHT',
  REVIEW_DETAIL_SCROLL_HEIGHT: 'REVIEW_DETAIL_SCROLL_HEIGHT',
  BOOKMARKED_REVIEW_TAB_SCROLL_HEIGHT: 'BOOKMARKED_REVIEW_TAB_SCROLL_HEIGHT',
  LATEST_REVIEW_TAB_SCROLL_HEIGHT: 'LATEST_REVIEW_TAB_SCROLL_HEIGHT',
  LAST_REAL_RESIDENCE_REVIEW_FEED_FILTER: 'LAST_REAL_RESIDENCE_REVIEW_FEED_FILTER',
  ANONYMOUS_TALK_LAST_VIEW_POST_PK: 'ANONYMOUS_TALK_LAST_VIEW_POST_PK',
} as const;

export const COOKIE_KEY = {
  NOT_SHOWING_PAYMENT_COMPLETE_POPUP: 'NOT_SHOWING_PAYMENT_COMPLETE_POPUP',
  LAST_CLICKED_DATE_FROM_COMMUNITY_AUTHENTICATION_POPUP: 'LAST_CLICKED_DATE_FROM_COMMUNITY_AUTHENTICATION_POPUP',
  INDUCTION_BOOKMARK_TOOLTIP: 'INDUCTION_BOOKMARK_TOOLTIP',
  PAY_POINT_DESCRIPTION_TOOLTIP: 'PAY_POINT_DESCRIPTION_TOOLTIP',
  IS_CHECKED_REAL_RESIDENCE_REVIEW_PHOTO_EVALUATION_DESCRIPTION_POPUP:
    'IS_CHECKED_REAL_RESIDENCE_REVIEW_PHOTO_EVALUATION_DESCRIPTION_POPUP',
  IS_CHECKED_EVALUATION_ENTRY_POPUP: 'IS_CHECKED_EVALUATION_ENTRY_POPUP',
  IS_CLICKED_FROM_DAILY_WRITE_FLOATING_BANNER: 'IS_CLICKED_FROM_DAILY_WRITE_FLOATING_BANNER',
  IS_CLOSED_REVIEW_INDUCE_BANNER: 'IS_CLOSED_REVIEW_INDUCE_BANNER',
  IS_CLICKED_FROM_APP_INSTALL_INDUCE_BOTTOM_SHEET: 'IS_CLICKED_FROM_APP_INSTALL_INDUCE_BOTTOM_SHEET',
  LESSOR_ONBOARDING_BUILDING_ROOM_INFO: 'LESSOR_ONBOARDING_BUILDING_ROOM_INFO',
  IS_ALREADY_CHECKED_BENEFIT_BOTTOM_SHEET: 'IS_ALREADY_CHECKED_BENEFIT_BOTTOM_SHEET',
} as const;
